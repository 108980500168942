import React from 'react';
import './App.css';
import {Marker, MapContainer, Popup, TileLayer} from 'react-leaflet';
import POI_MAP from './poi-map.json';

class App extends React.Component {
    render() {
        return <MapContainer bounds={[[49.29899, 14.24712], [54.79086, 23.89251]]}
                             style={{height: "100vh", width: "100vw"}}>
            <TileLayer
                attribution='&copy; <a href="https://osm.org/copyright">OpenStreetMap</a> contributors'
                url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
            />

            {POI_MAP.poiList.map(poi =>
                <Marker key={poi.id} position={[poi.lat, poi.lng]}>
                    <Popup>
                        <h3>{poi.name}</h3>
                        <p>{poi.remarks}</p>
                    </Popup>
                </Marker>
            )}

        </MapContainer>
    }
}

export default App;